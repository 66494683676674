<template lang="pug">
button.btn(
  :style="{color: customColor}"
  :class="{'btn_small': small, 'btn_block': block}"
  :disabled="disabled"
  @click="click" 
)
  .btn__item
    slot
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    transparent: Boolean,
    color: String,
    small: Boolean,
    large: Boolean,
    block: Boolean
  },

  computed: {
    customColor() {
      if(this.color in this.$vuetify.theme.themes.light) 
        return this.$vuetify.theme.themes.light[this.color]
      return this.color
    },
  },

  methods: {
    click() {
      if(this.disabled) return
      this.$emit('click')
    }
  },
}
</script>

<style lang="scss">
.btn {
  position: relative;
  color: $placeholder-color;
  min-height: 24px;
  padding: 0 8px;
  background-color: currentColor;
  border-radius: 4px;
  &:hover {
    box-shadow: 0 0 1px 2px currentColor;
  }
  &__wrapper {
    z-index: -1;
    opacity: .3;
    
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
  }
  &_transparent {
    background: transparent;
  }
  &__item {
    font-family: $font;
    font-weight: bold;
    font-size: 11px;
    color: #fff;
  }
  &_small {
    padding: 0 4px;
    min-height: 16px;
    & .btn {
      &__item {
        font-size: 10px;
      }
    }
  }
  &_block {
    width: 100%;
  }
}
</style>